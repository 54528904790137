import envSchema from "env-schema";
const schema = {
    type: 'object',
    required: [ 'VUE_APP_API_BASE_URL', 'VUE_APP_BASE_URL'],
    properties: {
        VUE_APP_RISK_LOW_THRESHOLD: {
            type: 'number' ,
            minimum: 0,
            maximum: 100,
            default: 30
        },
        VUE_APP_RISK_MEDIUM_THRESHOLD: {
            type: 'number' ,
            minimum: 0,
            maximum: 100,
            default: 69
        },
        VUE_APP_RISK_HIGH_THRESHOLD: {
            type: 'number' ,
            minimum: 0,
            maximum: 100,
            default: 100
        },
        VUE_APP_RISK_SCORE_TRESHOLD: {
            type: 'number' ,
            minimum: 0,
            maximum: 100,
            default: 55
        },
        VUE_APP_RISK_MEDIUM_DEFAULT: {
            type: 'number' ,
            minimum: 0,
            maximum: 100,
            default: 50
        },
        VUE_APP_TIMEOUT_MONITORING_LIST: {
            type: 'number' ,
            default: 30000
        },
        VUE_APP_TIMEOUT_MONITORING_SCORE: {
            type: 'number' ,
            default: 25000
        },
        VUE_APP_TIMEOUT_TOKENS_MONITORING_REPORT: {
            type: 'number' ,
            default: 60000
        },
        VUE_APP_API_USERS_URL: {
            type: 'string',
            minLength: 2,
            maxLength: 128,
            default: 'https://dev1.glprotocol.com/api/'
        },
        VUE_APP_LABEL_API_URL: {
            type: 'string',
            minLength: 2,
            maxLength: 128,
            default: 'https://common.dev1.glprotocol.com/api-labeling/'
        },
        VUE_APP_DOCUMENT_GENERATOR_URL: {
            type: 'string',
            minLength: 8,
            maxLength: 128,
            default: 'https://pdfgen.dev1.glprotocol.com'
        },
        VUE_APP_COIN_TYPE: {
            type: 'boolean',
            default: false
        },
        VUE_APP_MULTI_LABELING: {
            type: 'boolean',
            default: true
        },
        VUE_APP_PDF_GENERATOR: {
            type: 'boolean',
            default: false
        },
        VUE_APP_THEME: {
            type: 'string',
            maxLength: 128,
            default: 'default'
        },
        VUE_APP_SCORING_FORMULA: {
            type: 'string',
            maxLength: 128,
            default: 'Series'
        },
        VUE_APP_FIAT_LIST: {
            type: 'string',
            default: '[{"key":"USD","label":"USD"},{"key":"EUR","label":"EUR"}]'
        },
        VUE_APP_SCORE_REQUEST_DIRECTION: {
            type: 'number' ,
            default: 2
        },
        VUE_APP_FORMULA_SELECT: {
            type: 'boolean',
            default: true
        },
        VUE_APP_SCORE_CHART_DONUT: {
            type: 'boolean' ,
            default: true
        },
        VUE_APP_MIN_MAX_TOTAL_FUNDS: {
            type: 'boolean' ,
            default: false
        },
        VUE_APP_UNIFIED_TOKEN: {
            type: 'boolean' ,
            default: false
        },
        VUE_APP_ESSENTIAL_REPORT: {
            type: 'boolean' ,
            default: false
        },
        VUE_APP_EXCLUDE_AMOUNT_CUR: {
            type: 'boolean' ,
            default: false
        },
        VUE_APP_EXCLUDE_MULTI_DIRECTION: {
            type: 'boolean' ,
            default: false
        },
        VUE_APP_FEATURE_SELF_TRANSACTIONS: {
            type: 'boolean' ,
            default: false
        },
        VUE_APP_DEFAULT_TOKEN: {
            type: 'string' ,
            default: 'supported'
        },
        VUE_APP_ESSENTIAL_API_URL: {
            type: 'string',
            minLength: 2,
            default: 'https://common.dev1.glprotocol.com/essential-api/'
        },
        VUE_APP_FEATURE_EMAIL_NOTIFICATIONS: {
            type: 'boolean' ,
            default: false
        },
        VUE_APP_QUERY_JSON: {
            type: 'boolean' ,
            default: false
        },
        VUE_APP_SHALLOW: {
            type: 'boolean' ,
            default: true
        },
        VUE_APP_ADVANCED_FILTERS: {
            type: 'boolean' ,
            default: false
        },
        VUE_APP_PDF_TABLE_LIMIT: {
            type: 'number',
            default: 50
        },
        VUE_APP_ENABLE_SPEC_SYMBOLS_VALIDATE: {
            type: 'boolean' ,
            default: false
        },
        VUE_APP_ENABLE_UNHOSTED_TRIGGER: {
            type: 'boolean' ,
            default: false
        },
        VUE_APP_ENABLE_NY: {
            type: 'boolean' ,
            default: false
        },
        VUE_APP_USER_GUIDE_FEATURE_FLAG: {
            type: 'boolean',
            default: true
        },
        VUE_APP_API_USER_GUIDE_BASE_URL: {
            type: 'string',
            default: 'https://btc.dev5.glprotocol.com/api-guides/'
        },
        VUE_APP_USER_GUIDE_MAX_MB_PDF_FILE: {
            type: 'number',
            default: 50
        },
        VUE_APP_API_DOCUMENTATION_LINK: {
            type: 'string',
            default: 'https://common.glprotocol.com/api-doc/'
        },
    }
}

const config = envSchema({
    schema: schema,
    data: process.env, // optional, default: process.env
    dotenv: true // load .env if it is there, default: false
})

export default config
